import storage from "./storage"
import { format, add } from 'date-fns';


export function dateToWords(drp_name, min_date, pref_date) {
  let min_months = min_date % 12;
  let min_years = (min_date - min_months) / 12;
  let remainder_months = min_months;
  let min_year_to_words;
  switch (min_years) {
      case 0: min_year_to_words = '';
      break;

      case 1: min_year_to_words = `one year` ;
      break;

      case 2: min_year_to_words = `two years`;

      break;

      case 3: min_year_to_words = `three years`;
      break;

      case 4: min_year_to_words = `four years`;
      break;

      case 5: min_year_to_words = `five years`;
      break;

      case 6: min_year_to_words = `six years`;
      break;

      case 7: min_year_to_words = `seven years`;
      break;

      case 8: min_year_to_words = `eight years`;
      break;

      case 9: min_year_to_words = `nine years`;
      break;

    default: min_year_to_words = min_years + ' years';
      break;
  }
  if(remainder_months !== 0) {
   
    if(min_months === 1 && min_year_to_words) {
      remainder_months = ` and ` + min_months + ' month';
    }
    else if (min_months === 1 && min_year_to_words === '') {
      remainder_months = min_months + ' month';
    }
    else if (min_months && min_year_to_words === '') {
      remainder_months = min_months + ' month';
    }
    else {  
      remainder_months = ` and ` + min_months + ' months';
    }
  }
  else {
    remainder_months = '';
  }
  let actual_min_date = add(new Date(), {months: min_date});
  let actual_pref_date = add(new Date(), {months: pref_date});
  actual_min_date = format(actual_min_date, 'MM/dd/yyyy');
  actual_pref_date = format(actual_pref_date, 'MM/dd/yyyy');
  let min_words = '';
  let pref_words = '';
  
  if(storage.getTempQuery().minimum_duration || storage.getDrpLoa().data?.data?.value[0].result.DRPInfoResponse.thirdPartyIdentification.requiredMonths?.length > 0) {
    min_words = `for at least ${min_year_to_words} ${remainder_months} until ${actual_min_date},`;
  }
  if(storage.getTempQuery().preferred_duration || storage.getDrpLoa().data?.data?.value[0].result.DRPInfoResponse.thirdPartyIdentification.recommendedMonths?.length > 0) {
    pref_words = `with a preferred end date of ${actual_pref_date}.`
  }
  
  let generic_words = 'Data sharing will begin once authorization has been successfully submitted';
  /*
  if(min_words || pref_words) {
    generic_words = `${drp_name} requires you to share your data for your account(s) ${min_words} ${pref_words} Data Sharing will begin once authorization has been successfully submitted.`
  }
  */
  return <>
    {generic_words}.
  </>   
}
  

  