import React, { useState} from "react";
import "../../../assets/styles/MultipleDataSharingActive.scss";
import "../../../assets/styles/_mixins/main.scss"
import { reformatDate } from "../../../utils/dateFormat";
import { user } from "../../../utils/userAuth";

const MultipleDataSharingActive = (agreements) => {
    const [activeLoas, setActiveLoas] = useState(agreements.agreements);
    let allAcctsWithLoa = [];
    if(Object.keys(activeLoas).length > 0) {
          for(const agreement in activeLoas ){
          // list all agreements
          for(const account in activeLoas[agreement].customerAccounts) {
                  allAcctsWithLoa.push(activeLoas[agreement].customerAccounts[account].customerAccountName);
  
              }
  
          }
      }
    const [caretOpen, setCaretOpen] = useState(false);

  return (
        <div className="data-info-body">
            <div className="row-data">
                <div className="row">
                    <div className="span5 text-label dsk-spacing">
                        &nbsp;
                    </div>
                    <div className="span7">
                        <div className="data-container">
                            <div>
                                <div className="account-information">
                                {allAcctsWithLoa && allAcctsWithLoa.length >= user.relatedAccounts.length && 
                                    <div className="data-desc" style={{position: 'relative'}}><div className="info-icon"></div>All eligible accounts are already authorized.</div>
                                }
                                    <div className="account-control" onClick={()=>{ caretOpen === true ? setCaretOpen(false) : setCaretOpen(true) }}>
                                        <span className={ caretOpen ? "red-caret-container-open" : "red-caret-container-close" }></span>
                                        View {allAcctsWithLoa.length} Already Authorized Account(s)
                                    </div>
                                </div>
                                { caretOpen && <div className="table-container auto-width">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Account</td>
                                                <td>Expires</td>
                                            </tr>

                                            {/*parse through all agreements*/}
                                            {
                                            activeLoas.map((acc) => {
                                                return <>
                                                <tr key={acc.agreementID}>
                                                    <td>
                                                        { 
                                                            acc.customerAccounts.map((cus, index) => {
                                                                return <>
                                                                    {cus.customerAccountName} 
                                                                    ( {cus.contractAccountName} ) {index === acc.customerAccounts.length-1 ? ' ' : <br></br> }  
                                                                </>
                                                            }) 
                                                         }
                                                    </td>
                                                    <td>{ reformatDate(acc.authorizationEffectiveEndDate) }</td>
                                                </tr> 
                                                </>
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                                    }
                           </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="span12 border-separator"></div>
                </div>

            </div>
        </div>
  );
};

export default MultipleDataSharingActive;
