import React from 'react';
import Load from "../../components/features/Load"
import * as constant from '../../constant';
import storage from '../../utils/storage';

const Redirection = () => {

try {
    const tempRedirect = storage.getTempRedirect();
    if(tempRedirect === null || tempRedirect === undefined || tempRedirect === '' || tempRedirect === 'undefined' || tempRedirect === 'null'){
      setTimeout(function(){
        window.location.href = `${constant.BASE_URL}/authorize/faces/pages/scope=r32ct?errorCode=UserLoggedOut`;
      }, 3000)
      setTimeout(function(){
        storage.clearAllStorage();
      }, 3500)
    }
    else {
      setTimeout(function(){
      window.location.href = tempRedirect;
    }, 3000)
      setTimeout(function(){
      storage.clearAllStorage();
    }, 3500)
    }
  } catch (err) {
    console.log(err);
    setTimeout(function(){
      window.location.href = `${constant.BASE_URL}/authorize/faces/pages/scope=r32ct?errorCode=UserLoggedOut`;
    }, 3000)
    setTimeout(function(){
      storage.clearAllStorage();
    }, 3500)
    return;
  }
  return (
    <>
    <Load></Load>
    <h1>Redirecting, please wait...</h1>
    </>
);
};

export default Redirection