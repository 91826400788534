import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as constant from "../../constant";
import storage from "../../utils/storage";

export const name = "loa";

export const createLoa = createAsyncThunk(
  `${name}/createLoa`,
  async (formData) => {
    try {
      const redirectUri = storage.getTempQuery().redirect_uri;

      const data = {
        authorizationEffectiveEndDate: formData.authorizationEffectiveEndDate,
        customerFirstName: formData.customerFirstName,
        customerLastName: formData.customerLastName,
        customerEmail: formData.email_address,
        customerAccounts: formData.customerAccounts,
        thirdPartyId: formData.thirdPartyId,
        thirdPartyAlternateID: formData.thirdPartyAlternateID,
        thirdPartyName: formData.thirdPartyName,
        thirdPartyEmail: formData.emailAddress,
        redirectUri: redirectUri,
        nameOrg: formData.nameOrg,
        addressCombinedStreetNameText: formData.addressCombinedStreetNameText,
        addressCityText: formData.addressCityText,
        addressPostalCode: formData.addressPostalCode,
        addressStateOrProvinceCode: formData.addressStateOrProvinceCode,
        bpType: formData.bpType,
        bpTypeText: formData.bpTypeText
      }
      const user = storage.getUser();
      const headers = { 
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${user.idToken.jwt}`
      }
      await axios.post(`${constant.CREATE_LOA}`, data, {headers: headers})
      .then((response)=> {
        storage.setCreateLoa(response);
        //For CREATE LOA ONLY append to new logout url with auth code
        if(response.data.scope && response.data.state && response.data.token_code) {
          const scope = response.data.scope;
          //const state = response.data.state;
          const state = storage.getTempQuery().state;
          const token_code = response.data.token_code;
          const modifiedURI = `${redirectUri}?code=${token_code}&state=${state}&scope=${scope}`;
          storage.setTempRedirect(modifiedURI);
        }
        return response;
      }).catch((e) => {
        console.log(e);
        const redirectUri = storage.getTempQuery().redirect_uri;
        const errorMessage = "General system failures encountered.";
        const errorCode = "ELIG05_GENERAL_FAILURES";
        if (redirectUri) {
            const modifiedURI = `${redirectUri}?errorCode=${errorCode}&error=${errorMessage}`;
            storage.setTempRedirect(modifiedURI);
            window.location.href = `${constant.LOGOUT_API}`;
        } else {
            storage.setTempRedirect(`${constant.BASE_URL}?errorCode=${errorCode}&error=${errorMessage}`);
            window.location.href = `${constant.LOGOUT_API}`;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  createLoa: {
    authorizationEffectiveStartDate: "",
    authorizationEffectiveEndDate: "",
    customerFirstName: "",
    customerLastName: "",
    customerEmail: "",
    customerAccount: "",
    thirdPartyId: "",
    thirdPartyAlternateID: "",
    thirdPartyName: "",
    thirdPartyEmail: ""
  },
  loadingCreateLoa: null,
};

const createLoaSlice = createSlice({
  name,
  initialState,
  reducers: {
    createLoaa: (state, action) => {
      state.createLoaa.push(action.payload);
    },
  },
  extraReducers: {
    [createLoa.pending]: (state) => {
      state.loadingCreateLoa = constant.HTTP_STATUS.PENDING;
    },
    [createLoa.fulfilled]: (state, { payload }) => {
      state.loadingCreateLoa = constant.HTTP_STATUS.FULFILLED;
      state.createLoaa = payload;
    },
    [createLoa.rejected]: (state) => {
      state.loadingCreateLoa = constant.HTTP_STATUS.REJECTED;
    },
  },
});

export const { createLoaa } = createLoaSlice.actions;

export default createLoaSlice.reducer;
