import '../../assets/styles/App.scss';
import { useState, useEffect } from 'react';
import {getURLStringParams} from '../../utils/url'

function NotFound () {
    const [cookieError, setCookieError] = useState(false);
    const urlParams = getURLStringParams(window.location.search);
    const error = urlParams?.get("error");
    
    useEffect(() => {
        if (error) {
            const decodedError = decodeURI(error).split("%");
            decodedError.map((a) => {
                if(a.substring(2) == "403012") {
                    setCookieError(true);
                } 
            })
        }
    }, [cookieError]);
    return (
        <>
        <section className='hero-container' style={{minHeight: "50vh"}}>
            {cookieError && 
                <h2 style={{color: "#0878b5"}}>Error Code 40312: No valid session found. Please enable the use of third-party cookies on your browser settings and try again.</h2>
            }
            {!cookieError && 
            <div>
            <h2 style={{whiteSpace: "normal", display: "flex", color: "#0878b5", wordBreak: "break-word" }}>HTTP Status 404 - {window.location.pathname}{window.location.search}</h2>
            <h2 style={{color: "#0878b5"}}>The requested resource is not available.</h2>
            </div>
            }
        </section>
        </>
    )
  }
  
  export default NotFound;