import React from "react";
import "../../../assets/styles/EndDataSharingForm.scss";
import { Link } from 'react-router-dom';

const EndDataSharingForm = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
        <div className="data-info-body">
            <div className="row-data">
                <div className="row">
                  <div className="data-information">
                    <div className="information-icon">&nbsp;</div>
                    <div className="data-desc-next">You are currently not sharing data. Once you <Link to="#" onClick={ () => { window.location.reload() } } className="terms-redirection">set up data sharing</Link>, 
                        if you choose to cancel, please return to this page.
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </form>
  );
};

export default EndDataSharingForm;
