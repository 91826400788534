import axios from "axios";
import * as constant from "../constant";
import storage, { User } from "./storage";
import { toast } from 'react-toastify';
import { clearCookies } from "./cookie";
import CryptoJS from "crypto-js";

const tempQuery = storage.getTempQuery();
const tempUser = { 
    bpNumber: ""
}

export function validateUser(userToken){
    const headers = {
        'Authorization': `Bearer ${userToken.idToken.jwt}`,
      }
        try {
             axios.post(`${constant.USERINFO_API}`, {}, 
            {
                headers: {
            Authorization: `Bearer ${userToken.accessToken.jwt}`,
        },
    }).then(
        response => { 
            tempUser.bpNumber = response.data['custom:BPNumber'];
            const encBPNumber = encodeURIComponent(CryptoJS.AES.encrypt(tempUser.bpNumber, constant.PUBLIC_KEY).toString());
        
            //BadPubKeycheck
            //var tempPubKey = "U2FsdGVkX1%20TmPDC77TiEpgYRyoeBZA04roHT2DFVJkd/Q8vOPlsVgevkfHDsmf4nUdOOeoWQwTqK5ZO/NBmo%208Ve7ybQj5yeQ2NXVbWuEnfkM0nwt3qp6YVB796i3y%20";
            var tempPubKey = encodeURIComponent(CryptoJS.AES.encrypt(constant.PUBLIC_KEY, tempUser.bpNumber).toString());
            while (decodeURIComponent(tempPubKey).trim().length != 128){
                tempPubKey = encodeURIComponent(CryptoJS.AES.encrypt(constant.PUBLIC_KEY, tempUser.bpNumber).toString());
            }
            const encPubKey = tempPubKey;

            if(!tempUser.bpNumber){
                storage.clearUser();
                 toast.error("unable to extract token", {
                    position: toast.POSITION.TOP_CENTER,
                    onClose: () => window.location.href = constant.BASE_URL
                  })
                  return
             }
             axios.get(`${constant.GET_SAP_ACCOUNT}?bpNumber=${encBPNumber}&publicKey=${encPubKey}`, {headers: headers})
             .then(res => {
                //User Account Number from response
                User.bpType = res.data.data.bpType;
                User.bpTypeText = res.data.data.bpTypeText;
                User.addressCombinedStreetNameText = res.data.data.addressCombinedStreetNameText
                User.addressCityText = res.data.data.addressCityText
                User.addressPostalCode = res.data.data.addressPostalCode
                User.addressStateOrProvinceCode = res.data.data.addressStateOrProvinceCode
                User.eligMsg = res.data.data.eligMsg
                if(res.data.data.bpType === "1" || res.data.data.bpType === 1) {
                    User.first_name = res.data.data.customerFirstName;
                    User.last_name = res.data.data.customerLastName;
                }
                else {
                    User.nameOrg = res.data.data.nameOrg;
                }
                User.email = res.data.data.customerEmail;
                if(res.data.data.relatedAccounts.length === 0) {
                    storage.clearUser();
                    toast.error("No Linked Accounts Found", {
                       position: toast.POSITION.TOP_CENTER,
                       onClose: () => window.location.href = constant.LOGOUT_API
                    })
                    return
                }
                //Multiple accounts linked
                User.relatedAccounts = res.data.data.relatedAccounts;
                User.bpNumber = tempUser.bpNumber;
                storage.setUser(User);
                if (tempQuery) {
                    toast.success("Logging you in... ", {
                        position: toast.POSITION.TOP_CENTER,
                        onClose: () => window.location.href = `${constant.BASE_URL}/home?response_type=${tempQuery.response_type}&client_id=${tempQuery.client_id}&scope=${tempQuery.scope}&redirect_uri=${tempQuery.redirect_uri}&state=${tempQuery.state}`
                      })
                }
                else if (!tempQuery) {
                    toast.success("Logging you in... ", {
                        position: toast.POSITION.TOP_CENTER,
                        onClose: () => window.location.href = `${constant.BASE_URL}/home`
                      })
                }
            }).catch((e) => {
                console.log("uncaught exception on getSapAccounts", e);
                storage.clearUser();
                clearCookies();
                toast.error("Unable to retrieve account", {
                    position: toast.POSITION.TOP_CENTER,
                    onClose: () => window.location.href = constant.LOGOUT_API
                  })
            });
            }
        ).catch((err) => {
            console.log(err);
            storage.clearUser();
            clearCookies();
            toast.error("Invalid token, please log in again.", {
                position: toast.POSITION.TOP_CENTER,
                onClose: () => window.location.href = constant.LOGOUT_API
              })
        })
    } catch (error) {
        console.log("axios error: ", error);
        window.location.href = `${constant.BASE_URL}`;
    }
}