import * as constant from "../../constant/index";
import "../../assets/styles/App.scss";
import storage from "../../utils/storage";
import { clearCookies } from "../../utils/cookie";
import "../../assets/styles/Logout.scss";

export function SessionClear() {
    storage.clearUser();
    storage.clearCreateLoa();
    storage.clearDrpLoa();
    storage.clearGetLoa();
    storage.clearRevokeLoa();
    clearCookies();
    window.location.href = `${constant.LOGOUT_API}`;
}