import React, { useEffect, useRef, useState } from 'react';
import '../../../assets/styles/Navbar.scss';
import logo from "../../../assets/images/logo.png";
import { Link } from 'react-router-dom';
import { isAuthenticated, user } from '../../../utils/userAuth';
import storage from '../../../utils/storage';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import LoginRedirect from '../../features/Logout';
import SessionExpired from '../../features/SessionExpired';
import AboutToExpire from '../../features/AboutToExpire';
import { clearCookies } from "../../../utils/cookie";
import * as constant from "../../../constant/index";

function Navbar() {
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const time = 600000
  const warningTime = 480000
  const [open, showModal] = useState(false)
  const [openWarning, showWarning] = useState(false)
  let timeout = useRef(null)
  let warningTimeout = useRef(null)
  const counter = useRef(0)
  
  const ActivateAutoLogout = () => {
    clearTimeout(timeout.current)
    clearTimeout(warningTimeout.current)
    const id = setTimeout(function(){ logoutTasks() } , time)
    timeout.current = id
    const warningId = setTimeout(warningLogout, warningTime)
    warningTimeout.current = warningId
  }
  const logoutTasks = () => {
    storage.clearUser();
    clearCookies();
    showModal(true)
  }

  const warningLogout = () => {
    showWarning(true)
  }

  const clearWarningTimeOut = (warningId) => {
    clearTimeout(timeout.current)
    clearTimeout(warningTimeout.current)
    ActivateAutoLogout()
  }

// ************************** Events *********************************//
  document.onclick = () => {
    if(!open && isAuthenticated(user)) {
      clearTimeout(timeout.current)
      clearTimeout(warningTimeout.current)
      // counter.current = 1;
      ActivateAutoLogout()
    }
  }

  document.onmousemove = () => {
    if(!open && isAuthenticated(user)) {
      clearTimeout(timeout.current)
      clearTimeout(warningTimeout.current)
      // counter.current = 1;
      ActivateAutoLogout()
    }
  }
  
  document.onkeyup = () => {
    if(!open && isAuthenticated(user)) {
      clearTimeout(timeout.current)
      clearTimeout(warningTimeout.current)
      // counter.current = 1;
      ActivateAutoLogout()
    }
  }
  document.onscroll = () => {
    if(!open && isAuthenticated(user)) {
      clearTimeout(timeout.current)
      // counter.current = 1;
      ActivateAutoLogout()
    }
  }
// ************************** Events *********************************//
  // console.log('counter:', counter.current);
  useEffect(() => {
    if(isAuthenticated(user)) {
      ActivateAutoLogout()
    }
  }, [counter.current])

  useEffect(() => {
    if(isAuthenticated(user)) {
      setisLoggedIn(true);     
     }
    else {
      setisLoggedIn(false);
    }
  }, [isLoggedIn]);
  const contentStyle = { width: '22%' };
    return (
    <>
        <nav className='navbar'>
            <div className='navbar-container'>  
                <Link to="/" className="navbar-logo">
                  <img src={logo} alt="Sempra Logo"/>
                </Link> 
              {isLoggedIn && window.location.pathname != '/terms-of-use' ? 
              <Popup trigger={<button className='account-logout'>Logout</button>}>
                <LoginRedirect></LoginRedirect>
              </Popup>
                : `` } 
            </div>
            <Popup open={open} trigger={<></>} {...{ contentStyle }}>
                <SessionExpired></SessionExpired>
            </Popup>
            <Popup open={openWarning} trigger={<></>} {...{ contentStyle }}>
                <AboutToExpire showWarning = {showWarning} clearWarningTimeOut = {clearWarningTimeOut} warningTimeout = {warningTimeout.current}></AboutToExpire>
            </Popup>
        </nav>
    </>
  )
}

export default Navbar;
