import React, { useEffect } from "react";
import * as constant from "../../constant/index";
import "../../assets/styles/App.scss";
import storage from "../../utils/storage";
import { clearCookies } from "../../utils/cookie";
import "../../assets/styles/Logout.scss";
import loading from "../../assets/images/loading.gif"
import { Link } from "react-router-dom";
const LoginRedirect = () => {
  let drp_name = null;
  try {
    if(storage.getDrpLoa().data?.data?.value[0].result.DRPInfoResponse.thirdPartyIdentification.company){
      drp_name = storage.getDrpLoa().data?.data?.value[0].result.DRPInfoResponse.thirdPartyIdentification.company;
    }
    else {
      drp_name = null;
    }
  }
  catch {
    drp_name = null;
  }
  useEffect(() => { 
    setTimeout(function(){
      storage.clearUser();
      storage.clearCreateLoa();
      storage.clearDrpLoa();
      storage.clearGetLoa();
      storage.clearRevokeLoa();
      clearCookies();
      window.location.href = `${constant.LOGOUT_API}`      
    }, 5000)
  }, []);

  return (
    <>
    <div className="logout-wrap">
    <img className="loading-gif" src={loading} alt="Loading gif"/>
    <b>Logging Out...</b>
    {drp_name ? 
    <p className="popup-text">You will be redirected to {drp_name} a third party website which is not part of SDG&E. The terms and conditions on the website will apply.</p>

    : 
    <p className="popup-text">You will be redirected to a third party website which is not part of SDG&E. The terms and conditions on the website will apply.</p>
     }
    <br/>
    <p className="popup-text">Not Redirecting? <Link className="redirect" to={`/logout`}>Redirect Now</Link></p>
    </div>
    </>
  );
};

export default LoginRedirect;
