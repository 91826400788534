import '../../assets/styles/App.scss';
import '../../assets/styles/MyAccount.scss';
import ReactGA from "react-ga4";

function Maintenance () {
    document.title = 'Maintenance | Demand Response Direct Participation';
    ReactGA.send("pageview");
    return (
        <>
        <section className='hero-container'>
            <div className='account-wrap'>
                <div className='account-form'>
                    <h1>SDG&E® Click-Through is under maintenance and temporarily unavailable.</h1>
                </div>
               
            </div>
        </section>
        
        </>
    )
  }
  
  export default Maintenance;