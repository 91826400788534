import React, { useState, useEffect } from "react";
import "../../../assets/styles/EndDataSharingForm.scss";
import storage from "./../../../utils/storage";
import { reformatDate } from "../../../utils/dateFormat";
import ReactGA from "react-ga4";

const EndSharingStepOne = ({ steps, listData, selectedData, setErrorMsg }) => {
    const [objVal, setObjVal] = useState({
        agreementID: null,
        customerAccounts: null,
        authorizationEffectiveStartDate: null,
        authorizationEffectiveEndDate: null
    });
  
    useEffect(() => {
    }, [objVal]);

    const onChange = (obj) => {
        const value = JSON.parse(obj);
        setObjVal({    
            ...objVal,
            agreementID : value.agreementID,
            customerAccounts : value.customerAccounts,
            authorizationEffectiveStartDate : value.authorizationEffectiveStartDate,
            authorizationEffectiveEndDate : value.authorizationEffectiveEndDate
        });
        selectedData(objVal)
    }

    const validateRequiredField = () => {
        if(objVal.agreementID !== null) {
            selectedData(objVal);
            steps(2);
            setErrorMsg([])
            document.title = 'Revocation: Step 2 of 2 | Demand Response Direct Participation';
            ReactGA.send("pageview");
        } else {
            setErrorMsg(["Please select a Letter of Authorization."])
        }
    }

  return (
        <>
            <div className="row">
                <div className="span5 text-label row">
                    Step 1 of 2: Select Letter of Authorization for the data sharing agreements that you want to end.
                </div>
            </div>
            <div className="row">
                <div><span className='label-req'></span>Required fields</div>
            </div>
            <div className='row'>
                <div className="span12 border-separator"></div>
            </div>
            <div className="row">
                Ending an Authorization Letter is effective immediately.
            </div>
            <div className="row">
                <div><span className='label-req'></span>Select Letter of Authorization</div>
            </div>
            <div className="row">
                <div className="data-container">
                    <div className="table-container1">
                        <table>
                            <tbody>
                                <tr>
                                    <th>Letter Of Authorization</th>
                                    <th>Accounts</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                </tr>


                            {   storage.getGetLoa()?.data?.data?.results ? 
                                storage.getGetLoa()?.data?.data?.results.map((result, index) => {
                                    return <React.Fragment key={index}>
                                            { result?.serviceProviderID === storage.getDrpLoa().data?.data?.value[0].result.DRPInfoResponse.thirdPartyIdentification.alternateId ?
                                                (result?.agreements.map(( agreement ) => {
                                                    return <tr key={agreement?.agreementID}>
                                                            <td className="loa-col"><input type="radio" name="pickOne" value={JSON.stringify(agreement)} onChange={(e) => onChange(e.target.value)} />{ agreement?.agreementID }</td>
                                                            <td>{agreement?.customerAccounts.map((account, index) => {
                                                                return <li key={index}>{ account?.customerAccountName } ({ account?.contractAccountName })</li>
                                                            })}</td>
                                                            <td>{ reformatDate(agreement?.authorizationEffectiveStartDate) }</td>
                                                            <td>{ reformatDate(agreement?.authorizationEffectiveEndDate) }</td>
                                                        </tr>
                                                }))
                                            : null}
                                    </React.Fragment>
                                }) : null
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row text-right">
                <button className="next-step" type="button" onClick={()=> validateRequiredField() }>
                    Next <span className="caret-container">&nbsp;</span>
                </button>
            </div>
        </>
  );
};

export default EndSharingStepOne;
