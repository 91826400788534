import axios from "axios";
import { decodeJwt } from "./jwt";
import storage, { User } from "./storage";
import { toast } from 'react-toastify';
import { paramHash } from "./library/urlHash";
import * as constant from '../constant/index';
import { clearCookies } from "./cookie";
import CryptoJS from "crypto-js";

const tempQuery = storage.getTempQuery();

export function altValidate(paramsHash){
            User.accessToken = decodeJwt(paramHash.accessToken);
            User.idToken = decodeJwt(paramHash.idToken);
            User.bpNumber = paramHash.bpNumber;
            User.email = paramHash.email;
            User.phoneNumber = paramHash.phoneNumber;
            const headers = {
              'Authorization': `Bearer ${User.idToken.jwt}`,
            }
            try {
              const encBPNumber = CryptoJS.AES.encrypt(User.bpNumber, constant.PUBLIC_KEY).toString();
              const encPubKey = CryptoJS.AES.encrypt(constant.PUBLIC_KEY, User.bpNumber).toString();
              axios.get(`${constant.GET_SAP_ACCOUNT}?bpNumber=${encBPNumber}&publicKey=${encPubKey}`, {headers: headers})
              .then(res => {
                //User Account Number from response
                User.bpType = res.data.data.bpType;
                User.bpTypeText = res.data.data.bpTypeText;
                User.addressCombinedStreetNameText = res.data.data.addressCombinedStreetNameText
                User.addressCityText = res.data.data.addressCityText
                User.addressPostalCode = res.data.data.addressPostalCode
                User.addressStateOrProvinceCode = res.data.data.addressStateOrProvinceCode
                User.eligMsg = res.data.data.eligMsg
                if(res.data.data.bpType === "1" || res.data.data.bpType === 1) {
                    User.first_name = res.data.data.customerFirstName;
                    User.last_name = res.data.data.customerLastName;
                }
                else {
                    User.nameOrg = res.data.data.nameOrg;
                }
                User.email = res.data.data.customerEmail;
                if(res.data.data.relatedAccounts.length === 0) {
                    storage.clearUser();
                    toast.error("No Linked Accounts Found", {
                       position: toast.POSITION.TOP_CENTER,
                       onClose: () => window.location.href = constant.LOGOUT_API
                    })
                    return
                }
                //Multiple accounts linked
                User.relatedAccounts = res.data.data.relatedAccounts;
                storage.setUser(User);
                if(tempQuery) {
                  toast.success("Logging you in... ", {
                      position: toast.POSITION.TOP_CENTER,
                      onClose: () => window.location.href = `${constant.BASE_URL}/home?response_type=${tempQuery.response_type}&client_id=${tempQuery.client_id}&scope=${tempQuery.scope}&redirect_uri=${tempQuery.redirect_uri}&state=${tempQuery.state}`
                    })
                }
                else if (!tempQuery) {
                  toast.success("Logging you in... ", {
                      position: toast.POSITION.TOP_CENTER,
                      onClose: () => window.location.href = `${constant.BASE_URL}/home`
                    })
                }
              }).catch((e) => {
                console.log("uncaught exception on getSapAccounts", e);
                storage.clearUser();
                clearCookies();
                toast.error("Unable to retrieve account", {
                    position: toast.POSITION.TOP_CENTER,
                    onClose: () => window.location.href = constant.LOGOUT_API
                  })
              });
            }
            catch (error) {
              console.log("axios error: ", error);
              window.location.href = `${constant.BASE_URL}`;
            }
             
           
}