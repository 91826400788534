import jwt_decode from "jwt-decode";

export function decodeJwt(encoded) {
    const decoded = jwt_decode(encoded);
    let expireDate = new Date(0);
    expireDate.setUTCSeconds(decoded.exp);
  
    return { expiration: expireDate, jwt: encoded };
  }
  
  export function isTokenExpired(token) {
    const checkDate = new Date().toISOString();
    if(token.expiration < checkDate){
      return true;
    }
    else {
      return false;
    }
  }
  