import React, { useEffect } from "react";
import * as constant from "../../constant/index";
import "../../assets/styles/App.scss";
import storage from "../../utils/storage";
import { clearCookies } from "../../utils/cookie";
import styles from "../../assets/styles/SessionExpire.module.scss";
import { Link } from "react-router-dom";
import { SessionClear } from "./SessionClear";
const SessionExpired = () => {
  useEffect(() => {
  }, []);
  
  const removeSession = () => {
    SessionClear();
  };

  return (
    <>
      <div className="logout-wrap white_content">
        <p className={styles.popupText}>For security your session has timed out.<br/>
          Please return to the Demand Response<br/>
          Provider webpage to restart the<br/>
          authorization process
        </p>
        <br/><br/>
        <button type="button" onClick = {()=>{ removeSession() }} className={styles.Okbutton}>OK</button>
      </div>
    {/* <div className={styles.popupOverlay}></div> */}
    </>
  );
};

export default SessionExpired;
