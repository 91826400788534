import secureLocalStorage from "react-secure-storage";

const storagePrefix = "clickthrough";

export let User = {
    bpNumber: "",
    first_name: "",
    last_name: "",
    idToken: "",
    accessToken: "",
    email: "",
    phoneNumber: "",
    nameOrg: "",
    relatedAccounts: "",
    addressCombinedStreetNameText: "",
    addressCityText: "",
    bpType: "",
    bpTypeText: "",
    addressPostalCode: "",
    addressStateOrProvinceCode: "",
    eligMsg: ""
}

const storage = {
    getUser() {
       const user = JSON.parse(secureLocalStorage.getItem(`${storagePrefix}user`));
        return user;
    },
    setUser(User) {
      secureLocalStorage.setItem(`${storagePrefix}user`, JSON.stringify(User));
    },
    getDrpLoa() {
      const drpLoa = JSON.parse(secureLocalStorage.getItem(`${storagePrefix}drpLoa`));
       return drpLoa;
    },
    setDrpLoa(Drp) {
      secureLocalStorage.setItem(`${storagePrefix}drpLoa`, JSON.stringify(Drp));
    },
    clearDrpLoa: () => {
      secureLocalStorage.removeItem(`${storagePrefix}drpLoa`);
    },
    getCreateLoa() {
      const createLoa = JSON.parse(secureLocalStorage.getItem(`${storagePrefix}createLoa`));
       return createLoa;
    },
    setCreateLoa(Loa) {
      secureLocalStorage.setItem(`${storagePrefix}createLoa`, JSON.stringify(Loa));
    },
    clearCreateLoa: () => {
      secureLocalStorage.removeItem(`${storagePrefix}createLoa`);
    },
    getRevokeLoa() {
      const revokeLoa = JSON.parse(secureLocalStorage.getItem(`${storagePrefix}revokeLoa`));
       return revokeLoa;
    },
    setRevokeLoa(Loa) {
      secureLocalStorage.setItem(`${storagePrefix}revokeLoa`, JSON.stringify(Loa));
    },
    clearRevokeLoa: () => {
      secureLocalStorage.removeItem(`${storagePrefix}revokeLoa`);
    },
    getGetLoa() {
      const getLoa = JSON.parse(secureLocalStorage.getItem(`${storagePrefix}getLoa`));
       return getLoa;
    },
    setGetLoa(Loa) {
      secureLocalStorage.setItem(`${storagePrefix}getLoa`, JSON.stringify(Loa));
    },
    clearGetLoa: () => {
      secureLocalStorage.removeItem(`${storagePrefix}getLoa`);
    },
    clearUser: () => {
      secureLocalStorage.removeItem(`${storagePrefix}user`);
    },
    getTempQuery() {
      const tempQuery = JSON.parse(secureLocalStorage.getItem('urlQueryTemp'));
      return tempQuery;
    },
    setTempQuery(queryParams) {
      secureLocalStorage.setItem(`urlQueryTemp`, JSON.stringify(queryParams));
    },
    clearTempQuery: () => {
      secureLocalStorage.removeItem(`urlQueryTemp`);
    },
    getTempRedirect() {
      const tempRedirect = JSON.parse(secureLocalStorage.getItem('logout_redirect'));
        return tempRedirect;
    },
    setTempRedirect(queryParams) {
      secureLocalStorage.setItem(`logout_redirect`, JSON.stringify(queryParams));
    },
    clearTempRedirect: () => {
      secureLocalStorage.removeItem(`logout_redirect`);
    },
    setIsAlternateLogin: (val) => {
      secureLocalStorage.setItem(`isAlternateLogin`, JSON.stringify(val));
    },
    getIsAlternateLogin: () => {
      const isAltLogin = JSON.parse(secureLocalStorage.getItem('isAlternateLogin'));
      return isAltLogin;
    },
    clearAllStorage: () => {
      secureLocalStorage.clear();
    }
  };

  export default storage;