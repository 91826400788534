import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as constant from "../../constant";
import storage from "../../utils/storage";
import CryptoJS from "crypto-js";

export const name = "revokeLoa";

export const revokeLoa = createAsyncThunk(
  `${name}/revokeLoa`,
  async (formData) => {
    try {

      const user = storage.getUser();
      let encBPNumber = CryptoJS.AES.encrypt(user.bpNumber, constant.PUBLIC_KEY).toString();
      let encAgreementID =CryptoJS.AES.encrypt(formData.agreementID,constant.PUBLIC_KEY).toString();
      let encPubKey = CryptoJS.AES.encrypt(constant.PUBLIC_KEY, user.bpNumber).toString();
      const data = {
        agreementID: encAgreementID,
        publicKey: encPubKey,
        bpNumber:encBPNumber
      }

      const headers = { 
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${user.idToken.jwt}`
      }
      await axios.post(`${constant.REVOKE_LOA}`, data, {headers: headers})
      .then((response)=> {
        storage.setRevokeLoa(response);
        return response;
      }).catch((e) => {
        console.log(e);
        const redirectUri = storage.getTempQuery().redirect_uri;
        const errorMessage = "General system failures encountered.";
        const errorCode = "ELIG05_GENERAL_FAILURES";
        if (redirectUri) {
            const modifiedURI = `${redirectUri}?errorCode=${errorCode}&error=${errorMessage}`;
            storage.setTempRedirect(modifiedURI);
            window.location.href = `${constant.LOGOUT_API}`;
        } else {
            storage.setTempRedirect(`${constant.BASE_URL}?errorCode=${errorCode}&error=${errorMessage}`);
            window.location.href = `${constant.LOGOUT_API}`;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  revokeLoa: {
    authorizationEffectiveStartDate: "",
    authorizationEffectiveEndDate: "",
    customerFirstName: "",
    customerLastName: "",
    customerEmail: "",
    customerAccount: "",
    thirdPartyId: "",
    thirdPartyAlternateID: "",
    thirdPartyName: "",
    thirdPartyEmail: ""
  },
  loadingCreateLoa: null,
};

const revokeLoaSlice = createSlice({
  name,
  initialState,
  reducers: {
    createLoaa: (state, action) => {
      state.createLoaa.push(action.payload);
    },
  },
  extraReducers: {
    [revokeLoa.pending]: (state) => {
      state.loadingCreateLoa = constant.HTTP_STATUS.PENDING;
    },
    [revokeLoa.fulfilled]: (state, { payload }) => {
      state.loadingCreateLoa = constant.HTTP_STATUS.FULFILLED;
      state.createLoaa = payload;
    },
    [revokeLoa.rejected]: (state) => {
      state.loadingCreateLoa = constant.HTTP_STATUS.REJECTED;
    },
  },
});

export const { revokeLoaa } = revokeLoaSlice.actions;

export default revokeLoaSlice.reducer;
