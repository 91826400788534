import React, { useEffect } from "react";
import * as hash from "../../utils/library/urlHash";
import { isTokenExpired } from "../../utils/jwt";
import Load from "../features/Load"
import { useNavigate } from "react-router-dom";
import '../../assets/styles/LoginRedirect.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { altValidate } from "../../utils/altValidate";
import storage from "../../utils/storage";
import "../../assets/styles/App.scss";

const AltVerify = () => {
  const navigate = useNavigate();
  useEffect(() => {
    //simulate login
    // return storage.setUser(User);
    //if user is already logged in
    // const isLogged = true;
    //simulate session clearing
    // return storage.clearUser();
    const isLogged = storage.getUser();
    if(isLogged){
      //if token exists
      if(isLogged.accessToken.jwt){
        if(!isTokenExpired(isLogged.accessToken)){
          toast.success("Success! Logging you in...", {
            position: toast.POSITION.TOP_CENTER,
            onClose: () => navigate('/home')
          })    
        }
        else {
          storage.clearUser();
          toast.error("Your token has expired.", {
            position: toast.POSITION.TOP_CENTER,
            onClose: () => navigate('/')
          });        }

      }
    }
    // if not logged in
    else {
      if (hash.idToken && hash.accessToken){
        const paramsHash = hash.paramHash;
        altValidate(paramsHash);
      }
      else {
      storage.clearUser();
      toast.error("unable to extract token", {
        position: toast.POSITION.TOP_CENTER,
        onClose: () => navigate('/')
      });

      }
    }
  }, []);

  return (
    <>
        <ToastContainer autoClose={3000} />
        <Load></Load>
        <h1>Verifying access...</h1>
    </>
  );
};

export default AltVerify;
