import {urlStore} from "./config";

const urlObj = urlStore();

export const POSTS_API = "https://jsonplaceholder.typicode.com/posts";

export const HTTP_STATUS = Object.freeze({
  PENDING: "PENDING",
  FULFILLED: "FULFILLED",
  REJECTED: "REJECTED",
});

export const REVOKE_LOA = urlObj.revoke_loa

export const BASE_URL = urlObj.base_url; 

export const AUTH_URL = urlObj.auth_url

export const BEARER_API = urlObj.bearer;

export const REDIRECT_URL = urlObj.redirect_url;

export const GET_LOA = urlObj.get_loa;

export const USERINFO_API = urlObj.userinfo_api;

export const GETDRP = urlObj.getdrp;

export const CREATE_LOA = urlObj.create_loa;

export const LOGOUT_API = urlObj.logout_api;

export const GIGYA_API = urlObj.gigya_api;

export const GET_SAP_ACCOUNT = urlObj.get_sap_account;

export const PUBLIC_KEY = urlObj.public_key;