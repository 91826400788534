import React, { useEffect } from "react";
import * as constant from "../../constant/index";
import "../../assets/styles/App.scss";
import storage from "../../utils/storage";
import { clearCookies } from "../../utils/cookie";
import styles from "../../assets/styles/SessionExpire.module.scss";
import loading from "../../assets/images/loading.gif"
import { Link } from "react-router-dom";
const AboutToExpire = (props) => {
  useEffect(() => {
  }, []);
  
  const removeSession = () => {
    storage.clearUser();
    clearCookies();
    window.location.href = `${constant.LOGOUT_API}`;
  };

  return (
    <>
      <div className="logout-wrap white_content">
        <p className={styles.popupText}>
          Your session will expire in two minutes.<br/>
          Please click OK to continue<br/>
        </p>
          <br/>
        <button type="button" className={styles.Okbutton} onClick= { () => { props.showWarning(false); props.clearWarningTimeOut(props.warningTimeout.current) }}>OK</button>
      </div>
      <div id="fade" className="black_overlay"></div>
    </>
  );
};

export default AboutToExpire;
