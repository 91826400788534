import '../../assets/styles/App.scss';
import '../../assets/styles/Login.scss';
import { useEffect, useState } from 'react';
import bill from "../../assets/images/bill-example.jpg";
import { Link, useNavigate } from 'react-router-dom';
import AltForm from "../sections/forms/AltForm";
import storage from '../../utils/storage';
import { isAuthenticated, user } from '../../utils/userAuth';
import { saml, response_type, client_id, scope, redirect_uri, preferred_duration, minimum_duration, state, queryParams } from "../../utils/library/urlParams";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { SessionClear } from '../features/SessionClear';

function Login () {    
    const [validateQuery, setValidateQuery] = useState(false);
    const navigate = useNavigate();
    const tempQuery = storage.getTempQuery();
    const errorMsg = localStorage.getItem('errorMsg');
    const disabled = localStorage.getItem('disabled')
    useEffect(() => {
        localStorage.setItem("errorMsg", errorMsg)
        //sessions / cognito sessions must not persist, log users out if user sessions are detected on ALL login pages
        document.title = 'Alternate Authentication | Demand Response Direct Participation';
        localStorage.setItem("title", "Authorization | Demand Response Direct Participation");

        if(isAuthenticated(user)){
            storage.setTempQuery(queryParams);
            SessionClear();
        }
        else {
            storage.clearUser();
            if(!saml) {
                storage.setTempQuery(queryParams);
                navigate('/');
            }
            if(saml === 'null') {
                storage.setTempQuery(queryParams);
                navigate('/');
            }
            if(queryParams.client_id) {
                storage.setTempQuery(queryParams);
            }
            if(saml && tempQuery && validateQuery === false ){   
                setValidateQuery(true);
                localStorage.setItem('submitClicked', false);
                navigate(`/authorize/faces/pages/alternateAuthentication.jsp?samlContext=${saml}&response_type=${tempQuery.response_type}&client_id=${tempQuery.client_id}&scope=${tempQuery.scope}&redirect_uri=${tempQuery.redirect_uri}&preferred_duration=${tempQuery.preferred_duration}&minimum_duration=${tempQuery.minimum_duration}&state=${tempQuery.state}`)
            }
            if(queryParams.redirect_uri) {
                storage.setTempRedirect(queryParams.redirect_uri);
            }
    }
      }, [validateQuery, tempQuery, queryParams, navigate]);
    return (
        <>
        <section className='hero-container'>
            <div className='account-wrap'>
                <div className='account-form'>
                    { 
                        errorMsg === 'true' && (disabled === 'false' || disabled === null)
                        ? <div className="error">
                                <div className="error-container">
                                    <div className="error-text">
                                        <div>
                                            <ExclamationCircleFilled style={{ fontSize: '27px' }} theme="outlined" /> &nbsp;&nbsp;
                                            <span>
                                                We are sorry, one or more of the required fields
                                                have not been formatted correctly. Please fix the following errors:<br/>
                                                <ul type="circle">
                                                    <li>Enter a valid SDGE <u>Account Number</u>.</li>
                                                    <li>Enter a valid Phone Number in the following format xxx-xxx-xxxx</li>
                                                </ul>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''
                        }
                        {
                            disabled === 'true' ? 
                            <div className="error">
                                <div className="error-container">
                                    <div className="error-text">
                                        <div>
                                            <span>
                                                <ExclamationCircleFilled style={{ fontSize: '27px' }} theme="outlined" /> &nbsp;&nbsp;
                                                We noticed your are having trouble logging in. We are happy to help. Please contact 1-800-411-7343 for assistance.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div> : ''
                        }
                    <h1>Authorize to receive my SDG&E® Energy Usage Data</h1>
                    <p className='label-text'><span className='label-req'></span>Required fields</p>

                    <div className='account-links'>
                        <Link to={validateQuery ? `/authorize/faces/pages/MyAccountLogin.jsp?samlContext=${saml}&response_type=${response_type}&client_id=${client_id}&scope=${scope}&redirect_uri=${redirect_uri}&preferred_duration=${preferred_duration}&minimum_duration=${minimum_duration}&state=${tempQuery.state}` : `/authorize/faces/pages/MyAccountLogin.jsp?samlContext=${saml}`} className="link-myaccount">
                            <p className='my-link'>
                                Verify with My Energy
                            </p>
                        </Link> 
                        <div className="link-alternate">
                            <p className='alt-link'>
                                Verify with Account Number
                            </p>
                        </div> 
                    
                    </div>
                   
                    {/* ALT form */}
                    <AltForm />
                    {/* ALT form */}
                    
                </div>
                <div className='account-image'>
                    <h3 className='account-title'>Account Number</h3>
                    <p className='account-text'>Use your bill to find your account number</p>
                    <img className='account-img' src={bill} alt="Bill example with account number"/>
                </div>
            </div>
            
        </section>
        </>
    )
  }
  
  export default Login;