import React, {useState, useEffect} from 'react'
import '../../../assets/styles/AccountForm.scss';
import {useNavigate } from "react-router-dom";
import caret from "../../../assets/images/RedCarat.png";
import load from 'load-script'
import { GIGYA_API } from '../../../constant';
import { customLangParams } from '../../../utils/library/mainAuthErr';
import storage from '../../../utils/storage';

const AccountForm = () => {
    const navigate = useNavigate();
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isVisible, setVisible] = useState(false);
    useEffect(() => {
        if (!isFirstLoad) {
            window.location.reload();
        } else {
            setIsFirstLoad(false)
        }
          load(GIGYA_API, (err, script) => {
            let gigya = window.gigya;
            gigya.accounts.showScreenSet({
                screenSet: "Default-Login-RegistrationLogin",
              startScreen: "LoginClickthrough",
              containerID: "userScreensDiv",
              customLang: customLangParams
              });
              gigya.socialize.addEventHandlers({
              onLogin: function() {
              localStorage.setItem('errorMsg', false);
              gigya.fidm.saml.continueSSO();
              storage.setIsAlternateLogin(false);
              }
              });
          });
    }, [ isVisible ]);
   
  return (
    <>
    <form>
         <div className='altForm'>
            <div className='form-row'>
            <div id='userScreensDiv'></div> 
            </div>
            <div className='form-row-footer'>
                <div className='form-foot'>
                    <a className='form-link' href="https://myenergycenter.com/portal/ForgotUsername?r32Flag=Y&=undefined" target="_blank" rel="noopener noreferrer"><img src={caret} alt="Red Caret" />Forgot User ID </a>
                    <span className='form-link-text'> or </span>
                    <a className='form-link' href="https://myenergycenter.com/portal/ForgotPassword?r32Flag=Y&=undefined" target="_blank" rel="noopener noreferrer">Password?</a>
                </div>
                <div className='form-foot'>
                    <a className='form-link' href="https://myenergycenter.com/portal/Register?r32Flag=Y&=undefined" target="_blank" rel="noopener noreferrer"><img src={caret} alt="Red Caret" />Don't have a My Energy User? Register Today </a>
                </div>
            </div>
        </div> 
    </form>
    </>
  )
}

export default AccountForm