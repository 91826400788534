import React, { useState, useEffect } from "react";
import "../../../assets/styles/DataSharingForm.scss";
import "../../../assets/styles/_mixins/main.scss"
import { reformatDate } from "../../../utils/dateFormat";

const DataSharingActive = (agreements) => {
    const [accountNum, setAccountNum] = useState(agreements.agreements[0].customerAccounts[0].customerAccountName);
    const [expireDate, setExpireDate] = useState(agreements.agreements[0].authorizationEffectiveEndDate);
    const [caretOpen, setCaretOpen] = useState(false);

  
    useEffect(() => {

    }, [accountNum, expireDate]);

  return (
        <div className="data-info-body">
            <div className="row-data">
                <div className="row">
                    <div><span className='label-req'></span>Required fields</div>
                </div>
                <div className="row">
                    <div className="span5 text-label">
                        Account Information
                    </div>
                    <div className="span7 dsk-spacing">
                        &nbsp;
                    </div>
                </div>
                <div className="row">
                    <div className="span5 text-label dsk-spacing">
                        &nbsp;
                    </div>
                    <div className="span7">
                        <div className="data-container">
                            <div className="data-information">
                                <div className='information-icon'>&nbsp;</div>
                            </div>
                            <div>
                                <div className="account-information">
                                    <div className="data-desc">All eligible accounts are already authorized.</div>
                                    <div className="account-control" onClick={()=>{ caretOpen === true ? setCaretOpen(false) : setCaretOpen(true) }}>
                                        <span className={ caretOpen ? "red-caret-container-open" : "red-caret-container-close" }>&nbsp;</span>
                                        &nbsp;View 1 Already Authorized Account(s)
                                    </div>
                                </div>
                                { caretOpen && <div className="table-container auto-width">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Account</td>
                                                <td>Expires</td>
                                            </tr>
                                            <tr key={agreements.agreements[0].agreementID}>
                                                    <td>{ accountNum }</td>
                                                    <td>{ reformatDate(expireDate) }</td>
                                            </tr> 
                                        </tbody>
                                    </table>
                                </div>}
                           </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="span12 border-separator"></div>
                </div>

            </div>
        </div>
  );
};

export default DataSharingActive;
