import { configureStore } from "@reduxjs/toolkit";

import reducer from "./slices";

const store = configureStore({
  reducer,
});

if (module.hot) {
  module.hot.accept("./", () => {
    const reducers = require("./slices").default;

    store.replaceReducers(reducers);
  });
}

export default store;
