import React, {useEffect, useState} from 'react'
import '../../assets/styles/Dashboard.scss';
import storage from '../../utils/storage';
import { useNavigate } from 'react-router-dom';
import DataSharingForm from "../sections/forms/DataSharingForm";
import DataSharingActive from "../sections/forms/DataSharingActive";
import EndDataSharingForm from "../sections/forms/EndDataSharingForm";
import EndDataSharingActive from "../sections/forms/EndDataSharingActive";
import { isAuthenticated, user } from '../../utils/userAuth';
import { useDispatch } from "react-redux";
import { getLoa } from "../../store/slices/getLoa";
import { getDrpss } from "../../store/slices/drp";
import Load from "../../components/features/Load"
import { SessionClear } from '../features/SessionClear';
import * as constant from "../../constant";
import ReactGA from "react-ga4";

export const Dashboard = () => {
    const [invalidQuery, setInvalidQuery] = useState(false);
    const [tabOne, setTabOne] = useState(true);
    const navigate = useNavigate();
    let isInvalid = [];
    const dispatch = useDispatch();
    const [passed, setPassed] = useState(false);
    const [getDrpName, setGetDrpName] = useState({});
    const [getLoaRecord, setGetLoaRecord] = useState({});
    const [getMultipleLoaRecord, setGetMultipleLoaRecord] = useState({});
    const [getSingleLoaRecord, setSingleLoaRecord] = useState({});
    const [sharedAccountCount, setSharedAccountCount] = useState(0);
    const [isMultipleAccount, setIsMultipleAccount] = useState(false);    
    const [newFormValue, setNewformValue] = useState({
    });
    const queryStrings = storage.getTempQuery();
    const [loading, showLoading] = useState(1)
    //validate url query strings stored
    try {
        if (!queryStrings || !queryStrings.client_id) {
            isInvalid.push('client_id');
        }
        else {
            if(!queryStrings.client_id || queryStrings.client_id === null || queryStrings.client_id === 'null' || queryStrings.client_id === undefined || queryStrings.client_id === 'undefined' || queryStrings.client_id === '' ) {
                isInvalid.push('client_id');
            }
        }

    }
    catch {
        isInvalid.push('client_id');
    }
    useEffect(() => {
       if(!isAuthenticated(user)) {
        SessionClear();
       }
       //if client_id is missing
       if(isInvalid.length > 0){
        setInvalidQuery(true);     
        } 
       const customerAccounts = [];
       user.relatedAccounts.map((a) => {
        customerAccounts.push(a.contractAccountID);
       })
        if (passed === false) {
            try {
                dispatch(getLoa({ customerAccounts: user.relatedAccounts }))
                .then((response)=> {
                    setPassed(true);
                    setGetLoaRecord(storage.getGetLoa());
                })
                .then((r) => {
                    dispatch(getDrpss({ client_id: storage.getTempQuery()?.client_id ? storage.getTempQuery().client_id : null }))
                    .then((response) => {
                        //const drp_info = storage.getDrpLoa().data?.data?.authorizationServiceResponse.operationResultList?.operationResult?.thirdPartyIdentification;
                        const drp_info = storage.getDrpLoa().data?.data?.value[0].result.DRPInfoResponse.thirdPartyIdentification;
                        setGetDrpName(drp_info);
                        
                        if((typeof drp_info.name === 'undefined')||(drp_info.status!=="Active")){
                            
                            const redirectUri = storage.getTempQuery().redirect_uri;
                            const errorMessage = "General system failures encountered.";
                            const errorCode = "ELIG05_GENERAL_FAILURES";

                            if (redirectUri) {
                                const modifiedURI = redirectUri+"?errorCode="+errorCode+"&error="+errorMessage;
                                storage.setTempRedirect(modifiedURI);
                                window.location.href = constant.LOGOUT_API;
                            } else {
                                storage.setTempRedirect(constant.BASE_URL+"?errorCode="+errorCode+"&error="+errorMessage);
                                window.location.href = `${constant.LOGOUT_API}`;
                            }
                        }
                    
                        //if multiple accounts found
                        if(user.relatedAccounts.length > 1) {
                            setIsMultipleAccount(true);
                            if(storage.getGetLoa().data.data.results) {
                                for(const getLOAs in storage.getGetLoa().data.data.results) {
                                    //validate DRP == serviceProviderID of loa
                                    if (storage.getGetLoa().data.data.results[getLOAs].serviceProviderID === drp_info.alternateId) {   
                                        setSharedAccountCount(1);
                                        setGetMultipleLoaRecord(storage.getGetLoa().data.data.results[getLOAs].agreements)
                                    }
                                }
                            }
                            else {
                                setSharedAccountCount(0);
                            }
                        }
                        //is single account
                        else {
                            setIsMultipleAccount(false);
                            //validate getLOA
                            if(storage.getGetLoa().data.data.results) {
                                for(const getLOAs in storage.getGetLoa().data.data.results) {
                                    //validate DRP == serviceProviderID of loa
                                    if (storage.getGetLoa().data.data.results[getLOAs].serviceProviderID === drp_info.alternateId) {   
                                        setSharedAccountCount(1);
                                        setSingleLoaRecord(storage.getGetLoa().data.data.results[getLOAs].agreements)
                                    }
                                }
                            } 
                            else {
                                setSharedAccountCount(0);
                            }
                        }
                    }).then(() => {
                        showLoading(0)
                    }).catch((error) => {
                        showLoading(0);
                    })
                })
            }
            catch (error) {
                setInvalidQuery(true);
                showLoading(0)     
                console.log(error)
            }
        }
    }, [invalidQuery, isInvalid, navigate, dispatch, newFormValue, passed, getDrpName]);
    if (user) 
    {
        if (!invalidQuery) {
            
            document.title = localStorage.getItem("title");
            ReactGA.send("pageview");

            return (
                <>
                    <section className='hero-container'>
                        <h1 className='hero-title'>Authorize to receive my SDG&E® Energy Usage Data</h1>
                        {getDrpName ? <h1 className='hero-title'>Manage My Data Sharing Preferences With {getDrpName.name}</h1> : '' }
                        <div className='main-container'>
                            <div className='detail-header'>
                                <div className='detail-body'>Customer Name: 
                                    { user.nameOrg && <span className='detail-info'> {user.nameOrg}</span>
                                    }
                                    { !user.nameOrg && <span className='detail-info'> {user.first_name} {user.last_name}</span>
                                    }
                                </div>
                            </div>
                            <div className='detail-data'>
                                <div className='account-tabs'>
                                    <div className={ tabOne ? "active tab-myaccount tabs":"tab-myaccount tabs" } onClick={()=>{ setTabOne(true); localStorage.setItem("title", "Authorization | Demand Response Direct Participation"); }}>
                                        <p className='my-tab'>
                                            Set Up Data Sharing
                                        </p>
                                    </div>
                                    <div className={ !tabOne ? "active tab-alternate tabs" : "tab-alternate tabs" } onClick={()=>{ setTabOne(false); localStorage.setItem("title", "Revocation: Step 1 of 2 | Demand Response Direct Participation"); }}>
                                        <p className='alt-tab'>
                                            End Data Sharing
                                        </p>
                                    </div>
                                </div>
                                { loading ? <Load></Load> : '' }
                                { !loading && tabOne && sharedAccountCount === 0 && <DataSharingForm drpInfo={getDrpName} isMultipleAccount={isMultipleAccount} /> }
                                { !loading && !tabOne && sharedAccountCount === 0 && <EndDataSharingForm /> }
                                { !loading && tabOne && sharedAccountCount > 0 && isMultipleAccount === true &&
                                <DataSharingForm drpInfo={getDrpName} isMultipleAccount={isMultipleAccount} agreements={getMultipleLoaRecord} /> 
                                }
                                { !loading && tabOne && sharedAccountCount > 0 && isMultipleAccount === false && <DataSharingActive agreements={getSingleLoaRecord} /> }
                                { !loading && !tabOne && sharedAccountCount > 0 && <EndDataSharingActive /> }
                            </div>
                        </div>
                    </section>
                </>
            )
        }
        // URL Query string parameters are missing
        else {
            return (
                <>
                <section className='hero-container'>
                {/* should only return this If URL QUERY STRINGS are MISSING or INVALID */}
                    <h1 className='hero-title'>Authorize to receive my SDG&E® Energy Usage Data</h1>
                    <p>Please start the authorization process by going to your energy savings service provider website.</p>
                </section>
                </>
            )
        }
    }
}

export default Dashboard
