import Cookies from 'js-cookie';

export function clearCookies(){
    const cookie_names = [];
    const getAll = Cookies.get();
    if(getAll) {    
        const cookie_name = Object.keys(getAll);
        if(cookie_name){
            for (let x in cookie_name) {
                Cookies.remove(cookie_name[x]);
                cookie_names.push(cookie_name[x]);
            }
        }
    }
    return cookie_names;
}