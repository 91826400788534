import React, { useEffect } from "react";
import { getUrlHashParams } from "../../utils/url";
import { decodeJwt, isTokenExpired } from "../../utils/jwt";
import Load from "../features/Load"
import { useNavigate } from "react-router-dom";
import '../../assets/styles/LoginRedirect.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validateUser } from "../../utils/validate";
import storage, { User } from "../../utils/storage";
import { SessionClear } from "../features/SessionClear";

import "../../assets/styles/App.scss";

const LoginRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const isLogged = storage.getUser();
    if(isLogged){
      //if token exists
      if(isLogged.accessToken.jwt){
        if(!isTokenExpired(isLogged.accessToken)){
          toast.success("Success! Logging you in...", {
            position: toast.POSITION.TOP_CENTER,
            onClose: () => navigate('/home')
          })    
        }
        else {
          storage.clearUser();
          toast.error("Your token has expired.", {
            position: toast.POSITION.TOP_CENTER,
            onClose: () => {
              SessionClear();
            }
          });        
        }

      }
    }
    // if not logged in
    else {
      const params = getUrlHashParams(window.location.hash);
      const access = params?.get("access_token");
      const id = params?.get("id_token");
      if (id && access){
        User.accessToken = decodeJwt(access);
        User.idToken = decodeJwt(id);
        validateUser(User);
      }
      else {
      storage.clearUser();
      toast.error("unable to extract token", {
        position: toast.POSITION.TOP_CENTER,
        onClose: () => {
          SessionClear();
        }
      });

      }
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
        <ToastContainer autoClose={3000} />
        <Load></Load>
        <h1>Verifying access...</h1>
    </>
  );
};

export default LoginRedirect;
