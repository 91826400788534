// import logo from '../public/logo.png';
import './assets/styles/App.scss';
import './assets/styles/_mixins/main.scss';
import Navbar from './components/sections/templates/Navbar';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import NotFound from './components/pages/NotFound';
import AltLogin from './components/pages/AltLogin';
import MyAccountLogin from './components/pages/MyAccount';
import Maintenance from './components/pages/Maintenance';
import Footer from './components/sections/templates/Footer';
import Auth from './Auth';
import LoginRedirect from './components/pages/LoginRedirect';
import Dashboard from './components/pages/Dashboard';
import Logout from './components/features/Logout';
import AltVerify from './components/pages/AltRedirect';
import Redirection from './components/pages/Redirection';
import TermsOfUse from './components/pages/TermsOfUse';

function App() {
  return (
    <> 
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={<Auth />} />
        <Route path='/authorize/faces/pages/alternateAuthentication.jsp' element={<AltLogin />} />
        <Route path='/authorize/faces/pages/MyAccountLogin.jsp' element={<MyAccountLogin />} />
        <Route path='/authorize/faces/pages/authorization.jsp' element={<MyAccountLogin />} />
        <Route path='/maintenance' element={<Maintenance/>} />
        <Route path='/welcome' element={<LoginRedirect />} />
        <Route path='/altverify' element={<AltVerify />} />
        <Route path='/home' element={<Dashboard />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/redirection' element={<Redirection />} />
        <Route path='*' element={<NotFound />} />
        <Route path='/terms-of-use' element={<TermsOfUse />} />
      </Routes>
      <Footer />
    </Router>
    </>     
  );
}

export default App;
