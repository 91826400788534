import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as constant from "../../constant";
import storage from "../../utils/storage";

export const name = "getLOA";

export const getLoa = createAsyncThunk(
  `${name}/getLoa`,
  async (formData) => {
    try {
      const user = storage.getUser();
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${user.idToken.jwt}`,
      }
      const data = {
        customerAccounts: formData.customerAccounts
      }
      await axios.post(`${constant.GET_LOA}`, data, {headers: headers})
      .then((response)=> {
        storage.setGetLoa(response)
        return response;
      }).catch((e) => {
        console.log(e);
      const redirectUri = storage.getTempQuery().redirect_uri;
      const errorMessage = "General system failures encountered.";
      const errorCode = "ELIG05_GENERAL_FAILURES";
      if (redirectUri) {
          const modifiedURI = `${redirectUri}?errorCode=${errorCode}&error=${errorMessage}`;
          storage.setTempRedirect(modifiedURI);
          window.location.href = `${constant.LOGOUT_API}`;
      } else {
          storage.setTempRedirect(`${constant.BASE_URL}?errorCode=${errorCode}&error=${errorMessage}`);
          window.location.href = `${constant.LOGOUT_API}`;
      }
      });
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  getLoa: {

  },
  loadingGetLoa: null,
};

const getloaSlice = createSlice({
  name,
  initialState,
  reducers: {
    getLoaa: (state, action) => {
      state.getLoa.push(action.payload);
    },
  },
  extraReducers: {
    [getLoa.pending]: (state) => {
      state.loadingGetLoa = constant.HTTP_STATUS.PENDING;
    },
    [getLoa.fulfilled]: (state, { payload }) => {
      state.loadingGetLoa = constant.HTTP_STATUS.FULFILLED;
      state.getLoa = payload;
    },
    [getLoa.rejected]: (state) => {
      state.loadingGetLoa = constant.HTTP_STATUS.REJECTED;
    },
  },
});

export const { getLoaa } = getloaSlice.actions;

export default getloaSlice.reducer;
