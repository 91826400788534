export function getUrlHashParams(hash) {
    if (!hash) return undefined;
  
    if (hash.charAt(0) === "#") {
      hash = hash.substring(1);
    }
    const paramMap = new Map();
  
    hash.split("&").forEach((param) => {
      const [key, value] = param.split("=");
      paramMap.set(key, value);
    });
  
    return paramMap;
  }
  
  export function getURLStringParams(string) {
    if (!string) return undefined;
  
    if (string.charAt(0) === "?") {
      string = string.substring(1);
    }
    const paramMap = new Map();
  
    string.split("&").forEach((param) => {
      const [key, value] = param.split("=");
      paramMap.set(key, value);
    });
  
    return paramMap;
  }
  