import React from 'react';
import Load from "./components/features/Load"
import * as constant from './constant';
import { user } from './utils/userAuth';
import { SessionClear } from './components/features/SessionClear';


const Auth = () => {
if(user){
   SessionClear();
}    
else {
    window.location.href = constant.AUTH_URL + constant.REDIRECT_URL;

}
return (
    <>
    <Load></Load>
    <h1>Redirecting, please wait...</h1>
    </>
);
};

export default Auth