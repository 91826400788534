import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../../assets/styles/AltForm.scss";
import { userLogin } from "../../../store/slices/users";
import storage from "../../../utils/storage";
const AltForm = () => {
  const dispatch = useDispatch();
  const redirectURI = storage.getTempRedirect();

  const { users, loading } = useSelector((state) => state.users);

  const [formValue, setformValue] = useState({
    accountNumber: "",
    phoneNumber: "",
    submitClicked: false
    });

  const handleChange = (e) => {
    if(e.target.name === 'phoneNumber') {
      formatToPhone(e)
    }
    setformValue({
      ...formValue,
      [e.target.name]: e.target.value,
    });
  };

  const formatToPhone = (event) => {
    const target = event.target;
    const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
    const zip = input.substring(0,3);
    const middle = input.substring(3,6);
    const last = input.substring(6,10);

    if(input.length > 6){target.value = `${zip}-${middle}-${last}`;}
    else if(input.length > 3){target.value = `${zip}-${middle}`;}
    else if(input.length > 0){target.value = `${zip}`;}
  };
  
  const handleSubmit = (event) => {
    event.preventDefault()
    setformValue({ ...formValue, submitClicked: true });
    localStorage.setItem('submitClicked', true);
    if(formValue.accountNumber.length > 0 && formValue.phoneNumber.length > 0) {
      dispatch(userLogin(formValue));
      storage.setIsAlternateLogin(true);
    }
    return;
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="altForm">
        <div className="form-row">
           <div className="form-label">
            <span className="label-req"></span>
            <label>SDG&E Account Number: </label>
          </div>
          <div className="form-value">
            <input
              type="text"
              name="accountNumber"
              value={formValue.accountNumber}
              onChange={handleChange}
              className={formValue.accountNumber.length === 0 && formValue.submitClicked ? 'focus-active' : ''}
            />
            <span className="form-text">
              First 10 digits found at the top of your bill.
            </span>
          </div>
        </div>
         <div className="form-row">
          <div className="form-label">
            <span className="label-req"></span>
            <label>Phone Number: </label>
          </div>
          <div className="form-value">
            <input
              type="text"
              name="phoneNumber"
              value={formValue.phoneNumber}
              onChange={handleChange}
              className={formValue.phoneNumber.length === 0 && formValue.submitClicked ? 'focus-active' : ''}
            />
            <span className="form-text">
              Phone number associated with account on file.
            </span>
          </div>
        </div> 
      </div> 
      <div className="account-buttons">
        <button className="next" type="submit">
         {loading === "PENDING" ? 'LOADING' : 'NEXT'}  <span className="caret-container">&nbsp;</span>
        </button>
          {redirectURI !== null && redirectURI !== '' && redirectURI !== undefined && redirectURI !== 'undefined' ? 
        <button className='cancel' onClick={() => {
          storage.clearTempQuery();
          storage.clearTempRedirect();
          window.location.href = redirectURI
          }}
          >Cancel</button> 
          : 
          <button className='cancel' onClick={() =>  {
            storage.clearTempQuery();
            storage.clearTempRedirect();
            window.history.go(-5);
            }}
          >Cancel
          </button>
          }                                       
      </div>
    </form>
  );
};

export default AltForm;
