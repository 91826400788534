import React, { useEffect } from "react";
import * as constant from "../../constant/index";
import "../../assets/styles/App.scss";
import storage from "../../utils/storage";
import { clearCookies } from "../../utils/cookie";
import "../../assets/styles/Logout.scss";
import GreenCheckMark from "../../assets/images/GreenCheckMark.png";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

const CreatedLoaRedirect = () => {
  let drp_name = null;
  try {
    if(storage.getDrpLoa().data?.data?.value[0].result.DRPInfoResponse.thirdPartyIdentification.company){
      drp_name = storage.getDrpLoa().data?.data?.value[0].result.DRPInfoResponse.thirdPartyIdentification.company;
    }
    else {
      drp_name = null;
    }
  }
  catch {
    drp_name = null;
  }
  document.title = 'Authorization Complete | Demand Response Direct Participation';
  ReactGA.send("pageview");
    useEffect(() => {
      setTimeout(function(){
        storage.clearUser();
        storage.clearCreateLoa();
        storage.clearDrpLoa();
        storage.clearGetLoa();
        storage.clearRevokeLoa();
        clearCookies();
        window.location.href = `${constant.LOGOUT_API}`      
      }, 5000)
  }, []);
  return (
    <>
    {drp_name 
    ? 
    <p className="popup-text">Thank you for submitting your authorization request to SDG&E.  <b>To complete the process, you must wait to be redirected</b> to {drp_name} to finish enrollment.</p> 
    : 
    <p className="popup-text">Thank you for submitting your authorization request to SDG&E.  <b>To complete the process, you must wait to be redirected</b> to finish enrollment. </p> }
    
    <br/>
    <br/>
    <p className="popup-text">Not Redirecting? <Link className="redirect" to={`/logout`}>Redirect Now</Link></p>
    </>
  );
};

export default CreatedLoaRedirect;
