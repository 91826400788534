import React, { useState, useEffect } from "react";
import "../../../assets/styles/EndDataSharingForm.scss";
import storage from "./../../../utils/storage";
import Popup from "reactjs-popup";
import LoginRedirect from "../../features/Logout";
import Load from "../../features/Load";
import { reformatDate } from "../../../utils/dateFormat";
import ReactGA from "react-ga4";

const EndSharingStepThree = ({ steps, selectedData, loading }) => {
    const setUpFunctional = () => {
        window.location.reload();
    }
    const [revokedLoa, setRevokedLoa] = useState(JSON.parse(selectedData))
    //const drp_name = storage.getDrpLoa().data?.data?.value[0].result.DRPInfoResponse.thirdPartyIdentification.company;
    const drp_name = storage.getDrpLoa().data?.data?.value[0].result.DRPInfoResponse.thirdPartyIdentification.name;
    const imDoneBtn = () => {
    return  <Popup trigger={<button className="next-step-revoke" type="button">I'm Done<span className="caret-container">&nbsp;</span></button>} modal>
    <LoginRedirect></LoginRedirect>
    </Popup>
    }
    const setUpBtn = () => {
        localStorage.setItem("title", "Authorization | Demand Response Direct Participation");
        return <button className="next-step-revoke" type="button" onClick={()=>{ setUpFunctional() }}>
        Set Up <span className="caret-container">&nbsp;</span>
    </button>;
    }
    
    useEffect(() => {
    }, [selectedData, revokedLoa, loading]);
    if(!loading) {
        document.title = 'Revocation Complete | Demand Response Direct Participation';
        ReactGA.send("pageview");
        return (
            <>
                <div className="row">
                    <div className="span5 text-label row">
                        Success!
                    </div>
                </div>
                <div className="row">
                    You have successfully ended the following Letter of Authorization:
                </div>
                <div className="row">
                    <div className="data-container">
                        <div className="table-container1">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Letter Of Authorization</th>
                                        <th>Accounts</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                    </tr>
                                    <tr>
                                        <td>{ revokedLoa.agreementID }</td>
                                        <td>{revokedLoa?.customerAccounts.map((account, index) => {
                                            return <li key={index}>{ account?.customerAccountName } ({ account?.contractAccountName })</li>
                                        })}</td>
                                        <td>{ reformatDate(revokedLoa.authorizationEffectiveStartDate) }</td>
                                        <td>{ reformatDate(revokedLoa.authorizationEffectiveEndDate) }</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* no other loa */}
                {!Array.isArray(storage.getGetLoa()?.data?.data?.Results) ? 
                <div className="row">
                    <div className="data-container">
                        <div className="data-information">
                            <div className="information-icon">&nbsp;</div>
                            <div className="data-desc">
                                There are currently no active Letters of Authorization.
                            </div>
                        </div>
                    </div>
                </div>
                :
                //if other active LOAs exist after revoke   
                <div className="row" style={{ marginTop: '30px' }}>
                    The following letter(s) of Authorization are still active:
                    <div className="data-container">
                        <div className="table-container1">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Letter Of Authorization</th>
                                        <th>Accounts</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                    </tr>
                                
                                    {Object.keys(storage.getGetLoa()?.data?.data?.Results).map((a) => {
                                        return (
                                            <>
                                            {storage.getGetLoa()?.data?.data?.Results[a].agreementIdentifier.agreementID !== revokedLoa.agreementID && 
                                            <tr key={storage.getGetLoa()?.data?.data?.Results[a].agreementIdentifier.agreementID}>
                                                <td>{ storage.getGetLoa()?.data?.data?.Results[a].agreementIdentifier.agreementID }</td>
                                                <td> <li>{ storage.getGetLoa()?.data?.data?.Results[a]?.Customer?.CurrentCustomerAccount?.customerAccountName } ()</li></td>
                                                <td>{ reformatDate(storage.getGetLoa()?.data?.data?.Results[a]?.agreementAuthorization?.authorizationEffectiveStartDate) }</td>
                                                <td>{ reformatDate(storage.getGetLoa()?.data?.data?.Results[a]?.agreementAuthorization?.authorizationEffectiveEndDate) }</td>
                                            </tr>
                                        }
                                            </>
                                        )
                                        
                                        
                                        })
                                        
                                    }    
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                }
            
                <div className="row">
                    <div className="span5 text-label row">
                        What would you like to do now?
                    </div>
                </div>
                <div className="row">
                    <div className="data-container">
                        <div className="account-list btn-revoke-redirect">
                            <div className="row">
                                <div className="row-label">I'm done. Take me<br/>back to {drp_name}</div>
                                <div>{ imDoneBtn() }</div>
                            </div>
                            <div className="row">
                                <div className="row-label">I want to Set Up data<br/>sharing with<br/>{drp_name}</div>
                                <div>{ setUpBtn() }</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    else {
        return (
            <>
                <Load></Load>
            </>
            )
    }
};

export default EndSharingStepThree;
