import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "../../../assets/styles/EndDataSharingForm.scss";
import EndSharingStepOne from "./EndSharingStepOne";
import EndSharingStepTwo from "./EndSharingStepTwo";
import EndSharingStepThree from "./EndSharingStepThree";
import storage from "./../../../utils/storage";
import { revokeLoa } from "../../../store/slices/revokeLoa";
import { ExclamationCircleFilled } from '@ant-design/icons';

const EndDataSharingActive = () => {
    const dispatch = useDispatch();
    const [currentStep, setCurrentStep] = useState(1);
    const [userSelectedData, setUserSelectedData] = useState({});
    const [errorMsg, setErrorMsg] = useState([])
    const [newFormValue, setNewformValue] = useState({
    });
    const [loading, showLoading] = useState(0)

    const handleSubmit = (event) => {
      event.preventDefault();
    };
    const stepCount = (count) => {
      setCurrentStep(currentStep + 1);
      if (currentStep + 1 === 3) {
        showLoading(1)
        dispatch(revokeLoa({ agreementID : userSelectedData.agreementID }))
        .then((response)=> {
            setNewformValue({
            });
            showLoading(0);
        })
        .catch((err) => {
          console.log(err);
          showLoading(0);
        })
      }
    };
    const selectedData = (data) => {
      setUserSelectedData(data? data : storage.getGetLoa()?.data?.data?.results[0]?.agreements[0]);
    };
    useEffect(() => {
    }, [userSelectedData]);

  return (
    <>
    {
      errorMsg.length > 0 ? 
      <div className="error">
        <div className="error-container">
            <div className="error-text">
                <ExclamationCircleFilled style={{ fontSize: '27px' }} theme="outlined" /> &nbsp;&nbsp;
                <b>We are sorry, one or more of the required fields have not been formatted correctly. Please fix following errors:</b>
                <ul>
                  {
                      errorMsg.map((x, index) => {
                          return <li key={index}>{x}</li>
                      })
                  }
                </ul>
            </div>
        </div>
      </div> : ''
    }
    <form onSubmit={handleSubmit}>
        <div className="data-info-body">
            <div className="row-data">
                <div className="row" style={{display: loading ? 'none' : ''}}>
                    { currentStep === 1 && <div className="step-1">&nbsp;</div>}
                    { currentStep === 2 && <div className="step-2">&nbsp;</div>}
                    { currentStep === 3 && !loading && <div className="step-3">&nbsp;</div>}
                </div>
                { currentStep === 1 && <EndSharingStepOne steps={ stepCount } listData={storage.getGetLoa()} selectedData={selectedData} setErrorMsg = {setErrorMsg} /> }
                { currentStep === 2 && <EndSharingStepTwo steps={ stepCount } selectedData={JSON.stringify(userSelectedData)} /> }
                { currentStep === 3 && <EndSharingStepThree steps={ stepCount } selectedData={JSON.stringify(userSelectedData)} loading={loading} /> }
            </div>
        </div>
    </form>
    </>
  );
};

export default EndDataSharingActive;
