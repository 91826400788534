export function urlStore() {
    const root = window.location.hostname.trim().toString();
    if(root === "localhost"){
        const urls = {
            base_url: process.env.REACT_APP_BASE_URL_LOCAL,
            auth_url: process.env.REACT_APP_AUTH_URL_DEV,
            bearer: process.env.REACT_APP_BEARER_ENDPOINT_DEV,
            redirect_url: process.env.REACT_APP_REDIRECT_URL_DEV,
            userinfo_api: process.env.REACT_APP_USERINFO_DEV,
            getdrp: process.env.REACT_APP_GETDRP_DEV,
            create_loa: process.env.REACT_APP_CREATE_LOA_DEV,
            logout_api: process.env.REACT_APP_LOGOUT_DEV,
            get_loa: process.env.REACT_APP_GET_LOA_DEV,
            get_sap_account: process.env.REACT_APP_GET_SAP_ACCOUNT_DEV,
            revoke_loa: process.env.REACT_APP_REVOKE_LOA_DEV,
            gigya_api: process.env.REACT_APP_GIGYA_DEV,
            public_key: process.env.REACT_APP_PUBLIC_KEY
        }
        return urls;
    }
    else if (root === "clickthrough.sdge-dev.modfactory.sempra.com") {
        const urls = {
            base_url: process.env.REACT_APP_BASE_URL_DEV,
            auth_url: process.env.REACT_APP_AUTH_URL_DEV,
            bearer: process.env.REACT_APP_BEARER_ENDPOINT_DEV,
            redirect_url: process.env.REACT_APP_REDIRECT_URL_DEV,
            userinfo_api: process.env.REACT_APP_USERINFO_DEV,
            getdrp: process.env.REACT_APP_GETDRP_DEV,
            create_loa: process.env.REACT_APP_CREATE_LOA_DEV,
            logout_api: process.env.REACT_APP_LOGOUT_DEV,
            get_loa: process.env.REACT_APP_GET_LOA_DEV,
            get_sap_account: process.env.REACT_APP_GET_SAP_ACCOUNT_DEV,
            revoke_loa: process.env.REACT_APP_REVOKE_LOA_DEV,
            gigya_api: process.env.REACT_APP_GIGYA_DEV,
            public_key: process.env.REACT_APP_PUBLIC_KEY
            
        }
        return urls
    }
    else if (root === "clickthrough.sdge-qa.modfactory.sempra.com") {
        const urls = {
            base_url: process.env.REACT_APP_BASE_URL_QA,
            auth_url: process.env.REACT_APP_AUTH_URL_QA,
            bearer: process.env.REACT_APP_BEARER_ENDPOINT_QA,
            redirect_url: process.env.REACT_APP_REDIRECT_URL_QA,
            userinfo_api: process.env.REACT_APP_USERINFO_QA,
            getdrp: process.env.REACT_APP_GETDRP_QA,
            create_loa: process.env.REACT_APP_CREATE_LOA_QA,
            logout_api: process.env.REACT_APP_LOGOUT_QA,
            get_loa: process.env.REACT_APP_GET_LOA_QA,
            get_sap_account: process.env.REACT_APP_GET_SAP_ACCOUNT_QA,
            revoke_loa: process.env.REACT_APP_REVOKE_LOA_QA,
            gigya_api: process.env.REACT_APP_GIGYA_QA,
            public_key: process.env.REACT_APP_PUBLIC_KEY
        }
        return urls
    }
    else if (root === "clickthrough.sdge-prd.modfactory.sempra.com") {
        const urls = {
            base_url: process.env.REACT_APP_BASE_URL_PRD,
            auth_url: process.env.REACT_APP_AUTH_URL_PRD,
            bearer: process.env.REACT_APP_BEARER_ENDPOINT_PRD,
            redirect_url: process.env.REACT_APP_REDIRECT_URL_PRD,
            userinfo_api: process.env.REACT_APP_USERINFO_PRD,
            getdrp: process.env.REACT_APP_GETDRP_PRD,
            create_loa: process.env.REACT_APP_CREATE_LOA_PRD,
            logout_api: process.env.REACT_APP_LOGOUT_PRD,
            get_loa: process.env.REACT_APP_GET_LOA_PRD,
            get_sap_account: process.env.REACT_APP_GET_SAP_ACCOUNT_PRD,
            revoke_loa: process.env.REACT_APP_REVOKE_LOA_PRD,
            gigya_api: process.env.REACT_APP_GIGYA_PRD,
            public_key: process.env.REACT_APP_PUBLIC_KEY
        }
        return urls
    }
    else {
        console.log("ENV not detected properly");
        return null
    }
}