import { combineReducers } from "@reduxjs/toolkit";

import commentReducer, { name as commentName } from "./comments";
import userReducer, { name as userName } from "./users";
import drpReducer, { name as drpName } from "./drp";

const rootReducer = combineReducers({
  [commentName]: commentReducer,
  [userName]: userReducer,
  [drpName]: drpReducer,
});

export default rootReducer;
