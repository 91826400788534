import React, { useState, useEffect } from "react";
import "../../../assets/styles/EndDataSharingForm.scss";
import { reformatDate } from "../../../utils/dateFormat";

const EndSharingStepTwo = ({ steps, selectedData }) => {
    const [selectedLoa, setSelectedLoa] = useState(JSON.parse(selectedData));
    useEffect(() => {
    }, [selectedData]);

  return (
        <>
            <div className="row">
                <div className="span5 text-label row">
                    Step 2 of 2: Review and Confirm
                </div>
            </div>
            <div className="row">
                <div>Confirm you want to end the following Letter of Authorization.</div>
            </div>
            <div className="row">
                Ending an authorization Letter is effective immediately.
            </div>
            <div className="row">
                <div className="data-container">
                    <div className="table-container1">
                        <table>
                            <tbody>
                                <tr>
                                    <th>Letter Of Authorization</th>
                                    <th>Accounts</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                </tr>
                                <tr>
                                    <td>{ selectedLoa?.agreementID }</td>
                                    <td>{selectedLoa?.customerAccounts.map((account, index) => {
                                        return <li key={index}>{ account?.customerAccountName } ({ account?.contractAccountName })</li>
                                    })}</td>
                                    <td>{ reformatDate(selectedLoa?.authorizationEffectiveStartDate) }</td>
                                    <td>{ reformatDate(selectedLoa?.authorizationEffectiveEndDate) }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row text-right">
                <button className="next-step" type="button" onClick={()=>{ steps(3); }}>
                    End Data Sharing <span className="caret-container">&nbsp;</span>
                </button>
            </div>
        </>
  );
};

export default EndSharingStepTwo;
