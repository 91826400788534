import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as constant from "../../constant";
import storage from "../../utils/storage";

export const name = "drp";

export const getDrpss = createAsyncThunk(
  `${name}/getDrp`,
  async (getData) => {
    try {
      const user = storage.getUser();
      const headers = {
        'Authorization': `Bearer ${user.idToken.jwt}`,
      }
      await axios.get(`${constant.GETDRP}?client_id=${getData.client_id}`, {headers: headers})
      .then((response)=> {
        storage.setDrpLoa(response);
        return response;
      }).catch((e) => {
        console.log(e);
        const redirectUri = storage.getTempQuery().redirect_uri;
        const errorMessage = "General system failures encountered.";
        const errorCode = "ELIG05_GENERAL_FAILURES";
        if (redirectUri) {
            const modifiedURI = `${redirectUri}?errorCode=${errorCode}&error=${errorMessage}`;
            storage.setTempRedirect(modifiedURI);
            window.location.href = `${constant.LOGOUT_API}`;
        } else {
            storage.setTempRedirect(`${constant.BASE_URL}?errorCode=${errorCode}&error=${errorMessage}`);
            window.location.href = `${constant.LOGOUT_API}`;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  getDrps: {
    client_id: "",
  },
  loadingDrps: null,
};

const drpSlice = createSlice({
  name,
  initialState,
  reducers: {
    getDrps: (state, action) => {
      let newParams = {
        client_id: action.payload.client_id,
      };
      state.getDrps.push(newParams);
    },
  },
  extraReducers: {
    [getDrpss.pending]: (state) => {
      state.loadingDrps = constant.HTTP_STATUS.PENDING;
    },
    [getDrpss.fulfilled]: (state, { payload }) => {
      state.loadingDrps = constant.HTTP_STATUS.FULFILLED;
      state.getDrps = payload;
    },
    [getDrpss.rejected]: (state) => {
      state.loadingDrps = constant.HTTP_STATUS.REJECTED;
    },
  },
});

export const { getDrps } = drpSlice.actions;

export default drpSlice.reducer;
