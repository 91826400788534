import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as constant from "../../constant";

export const name = "users";
let failedCounter = localStorage.getItem("failedCount") ? localStorage.getItem("failedCount") : 0;
let disabled = false
let data = null;
let error = false;
export const userLogin = createAsyncThunk(
  `${name}/userLogin`,
  async (formData) => {
    try {
      if (formData.accountNumber.length === 0 || formData.phoneNumber.length === 0) {
        localStorage.setItem('errorMsg', true);
      } else {
        localStorage.setItem('errorMsg', false);
      }
      await axios.post(`${constant.BEARER_API}`, null , {
        params: {
          accountNumber: formData.accountNumber,
          phoneNumber: formData.phoneNumber
        }
      }).then((response) => {
        data = response.data
        failedCounter = 0
        disabled = false
        error = false
      }).catch((e) => {
        failedCounter = parseInt(failedCounter) + 1
        disabled = failedCounter >= 6 ? true : false
        error = true
        console.log("e: ", e)
      }).finally(() => {
        localStorage.setItem("failedCount", failedCounter)
        localStorage.setItem("disabled", disabled)
        localStorage.setItem('errorMsg', error);
        //console.log("error: ", error);
        window.location.reload();
        //location.reload();
      });
      var params = null;
      if (data !== null) {
        params = data['data'];
      }
      if (params != null) {
        const idToken = params['idToken'];
        const accessToken = params['accessToken'];
        const bpNumber = params['BPNumber'];
        const first_name = params['firstName'];
        const last_name = params['lastName'];
        const accountNumber = params['accountNumber'];
        const phoneNumber = params['phoneNumber'];
        const email = params['email'];
        const expiresIn = params['ExpiresIn']  && params['ExpiresIn']  !== '' ? params['ExpiresIn'] : 3600;
        const tokenType = params['TokenType'] && params['TokenType'] !== '' ? params['TokenType'] : 'Bearer';
        const uri = `/altverify#access_token=${accessToken}&id_token=${idToken}&token_type=${tokenType}&expires_in=${expiresIn}&BPNumber=${bpNumber}&first_name=${first_name}&last_name=${last_name}&accountNumber=${accountNumber}&phoneNumber=${phoneNumber}&email=${email}`;
      
          window.location.href = encodeURI(uri);
      }
      return params;
    } catch (error) {
      console.log(error);
      localStorage.setItem('errorMsg', true);
      return error;
    }
  }
);

const initialState = {
  users: {
    accountNumber: "",
    phoneNumber: "",
  },
  loading: null,
};

const userSlice = createSlice({
  name,
  initialState,
  reducers: {
    login: (state, action) => {
      state.users = {
        accountNumber: action.payload.accountNumber,
        phoneNumber: action.payload.phoneNumber,
      };
    },
  },
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.loading = constant.HTTP_STATUS.PENDING;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = constant.HTTP_STATUS.FULFILLED;
      state.users = payload;
    },
    [userLogin.rejected]: (state) => {
      state.loading = constant.HTTP_STATUS.REJECTED;
    },
  },
});

export const { login } = userSlice.actions;

export default userSlice.reducer;
