import '../../../assets/styles/Footer.scss'
import footerlogo from "../../../assets/images/footer-logo.png";
import React from 'react'

function Footer() {
  return (
    <>
        <footer className='footer' role='contentinfo'>
            <div className='footer-bar'>
                <a href="https://www.sdge.com/more-information/our-company/customer-privacy/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                <a href="https://www.sdge.com/privacy-notices" target="_blank" rel="noopener noreferrer">Privacy Notice</a>
                <a href="https://www.sdge.com/customer-service/contact-us" target="_blank" rel="noopener noreferrer">Contact Us</a>
            </div>
            <div className='footer-content'>
                <p className='footer-text'>SDG&amp;E® and San Diego Gas &amp; Electric® are registered trademarks of San Diego Gas &amp; Electric Company.</p>
                <p className='footer-text'>© 1998-{(new Date().getFullYear())} San Diego Gas & Electric Company. The Trademarks used herein are the property of their respective owners. All rights reserved.</p>
            </div>
            <div className="footer-image">
                <img src={footerlogo} alt="SDGE Footer Logo" className='footer-logo' />
            </div>
        </footer>
        
    </>
    )
}

export default Footer