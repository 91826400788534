import storage from "./storage";
import { isTokenExpired } from "./jwt";
import { SessionClear } from "../components/features/SessionClear";

export const user = storage.getUser();
export function isAuthenticated(user) {
    if(!user || user == null) {
        return null;
    }
    if(isTokenExpired(user.accessToken)){
    SessionClear();
        return null
    }

    return user;
}