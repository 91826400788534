import { useState }  from 'react';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import React from 'react'

export const Load = () => {
    const override = css`
  display: block;
  margin: 50px auto;
`;
    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState("#0878b5");
  return (
    <div className='sweet-loader'>
      <ClipLoader color={color} loading={loading} css={override} size={250} />
    </div>
  )
}

export default Load;