import { getUrlHashParams } from "../url";

const urlParams = getUrlHashParams(window.location.hash);

export const accessToken = urlParams?.get("access_token");
export const idToken = urlParams?.get("id_token");
export const bpNumber = urlParams?.get("BPNumber");
export const first_name = urlParams?.get("first_name");
export const last_name = urlParams?.get("last_name");
export const phoneNumber = urlParams?.get("phoneNumber");
export const email = urlParams?.get("email");
export const accountNumber = urlParams?.get("accountNumber");

export const paramHash = {
    accessToken: accessToken,
    idToken: idToken,
    bpNumber: bpNumber,
    first_name: first_name,
    last_name: last_name,
    accountNumber: accountNumber,
    phoneNumber: phoneNumber,
    email: email
}